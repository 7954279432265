import payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M from "/home/forge/merchants.smake.construction/releases/v1.44.2/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/home/forge/merchants.smake.construction/releases/v1.44.2/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/home/forge/merchants.smake.construction/releases/v1.44.2/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/home/forge/merchants.smake.construction/releases/v1.44.2/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/home/forge/merchants.smake.construction/releases/v1.44.2/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/home/forge/merchants.smake.construction/releases/v1.44.2/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/home/forge/merchants.smake.construction/releases/v1.44.2/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/home/forge/merchants.smake.construction/releases/v1.44.2/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs from "/home/forge/merchants.smake.construction/releases/v1.44.2/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/home/forge/merchants.smake.construction/releases/v1.44.2/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/home/forge/merchants.smake.construction/releases/v1.44.2/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_NflG9_QeVcJ1jVig0vCfxB_cZhpEMQ9U2ujRUiYbbVw from "/home/forge/merchants.smake.construction/releases/v1.44.2/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect__HPHJq3Jg7gwhwgKEI8tQavopSAjmrCSPXl9HgL2h9U from "/home/forge/merchants.smake.construction/releases/v1.44.2/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_EI7LsD1KYQADczz5hrChviGQCdVM8yUkvFEZLJpmnvM from "/home/forge/merchants.smake.construction/releases/v1.44.2/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_i2gg1nN_mAiQgkfj0a_nkdbT9Eyfwuu6nklCpr_5J6o from "/home/forge/merchants.smake.construction/releases/v1.44.2/node_modules/@hebilicious/vue-query-nuxt/dist/runtime/plugin.mjs";
import _01_flare_client_IWEA1esxOAEQEqA3h9AAG1r_h5pXLtHVd9Fm_HT1dmY from "/home/forge/merchants.smake.construction/releases/v1.44.2/plugins/01.flare.client.ts";
import _02_smake_ui_client_DU1PjsL6ZmjN1WHJhDHTNWs0q_pGqZRj3wmmH5tSO_8 from "/home/forge/merchants.smake.construction/releases/v1.44.2/plugins/02.smake-ui.client.ts";
import _04_i18n_client_NW8mgUbhjxP9XfBJCDiGHjLp4EimYiJO_BSmd3h6qTE from "/home/forge/merchants.smake.construction/releases/v1.44.2/plugins/04.i18n.client.ts";
import ssg_detect_IpHCGcQQ_IR5Rl99qyukWoMA9fJGfuTYyoksTzy81cs from "/home/forge/merchants.smake.construction/releases/v1.44.2/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M,
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  switch_locale_path_ssr_NflG9_QeVcJ1jVig0vCfxB_cZhpEMQ9U2ujRUiYbbVw,
  route_locale_detect__HPHJq3Jg7gwhwgKEI8tQavopSAjmrCSPXl9HgL2h9U,
  i18n_EI7LsD1KYQADczz5hrChviGQCdVM8yUkvFEZLJpmnvM,
  plugin_i2gg1nN_mAiQgkfj0a_nkdbT9Eyfwuu6nklCpr_5J6o,
  _01_flare_client_IWEA1esxOAEQEqA3h9AAG1r_h5pXLtHVd9Fm_HT1dmY,
  _02_smake_ui_client_DU1PjsL6ZmjN1WHJhDHTNWs0q_pGqZRj3wmmH5tSO_8,
  _04_i18n_client_NW8mgUbhjxP9XfBJCDiGHjLp4EimYiJO_BSmd3h6qTE,
  ssg_detect_IpHCGcQQ_IR5Rl99qyukWoMA9fJGfuTYyoksTzy81cs
]