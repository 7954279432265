
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexVPh3UwWS_452M_8SjSv_wmUfFaMfXENvX1MuBBOml2gYsMeta } from "/home/forge/merchants.smake.construction/releases/v1.44.2/pages/index.vue?macro=true";
import { default as index5YFD8QEomaM7FtEsPIGNa6KDTrTL8nX8uXMHLuQH8ZgMeta } from "/home/forge/merchants.smake.construction/releases/v1.44.2/pages/[slug]/index.vue?macro=true";
import { default as loginJXTv_0Of7_Xgkgqw36qqFEYut2H4CBhFHZhfgsbEhDMMeta } from "/home/forge/merchants.smake.construction/releases/v1.44.2/pages/[slug]/login.vue?macro=true";
import { default as entrancenLLnwRSZG0N_45qF23YLGBq8GNznQ3mpmu49cx3l5u3tIMeta } from "/home/forge/merchants.smake.construction/releases/v1.44.2/pages/[slug]/entrance.vue?macro=true";
import { default as dashboardwJZbrZNjV0FRuMvGcGHV8kGo4Z5gfEdHSF2RM8ZtiFoMeta } from "/home/forge/merchants.smake.construction/releases/v1.44.2/pages/[slug]/dashboard.vue?macro=true";
import { default as indexjoEIlvs_45Nym8BR6_IPudVLGqfS9xWyxiq21O2gU6DUcMeta } from "/home/forge/merchants.smake.construction/releases/v1.44.2/pages/[slug]/customers/index.vue?macro=true";
import { default as _91id_93V0RodU15NzO_45XjKJyc4ot8G73vzFDPnN_Y1qtUVvKPAMeta } from "/home/forge/merchants.smake.construction/releases/v1.44.2/pages/[slug]/catalog/logos/[id].vue?macro=true";
import { default as index7_454zyM7_45J0iu2qo9ZGy9BH0EL1WjbigIUbcyxAQMhjoMeta } from "/home/forge/merchants.smake.construction/releases/v1.44.2/pages/[slug]/catalog/logos/index.vue?macro=true";
import { default as indexlJLZzyk0ho7FlNs6En7L8t0UjXLvn94o0Q14xRiKLUEMeta } from "/home/forge/merchants.smake.construction/releases/v1.44.2/pages/[slug]/customers/[id]/index.vue?macro=true";
import { default as colorsDByKTen_xnz336e4wHRC4UdyH_457BfXEsPPAZBMO9uYgMeta } from "/home/forge/merchants.smake.construction/releases/v1.44.2/pages/[slug]/customers/[id]/colors.vue?macro=true";
import { default as _91id_93qK8uUnE0mPDUP1W0S6jIoxWTtz_rxKeQTqLQAyoKnMsMeta } from "/home/forge/merchants.smake.construction/releases/v1.44.2/pages/[slug]/customers/[customer]/logos/[id].vue?macro=true";
import { default as indexw8F_9JNC8FW6MND16U7sOGBX_45t9LD11SWLz8FU8TFKYMeta } from "/home/forge/merchants.smake.construction/releases/v1.44.2/pages/[slug]/customers/[customer]/logos/index.vue?macro=true";
import { default as favorite_45variantsbkLViL48j4i2J0OjsuTyf_45zOhALyLD5ZV6d_45cbloaFMMeta } from "/home/forge/merchants.smake.construction/releases/v1.44.2/pages/[slug]/customers/[id]/favorite-variants.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/home/forge/merchants.smake.construction/releases/v1.44.2/pages/index.vue")
  },
  {
    name: "slug",
    path: "/:slug()",
    component: () => import("/home/forge/merchants.smake.construction/releases/v1.44.2/pages/[slug]/index.vue")
  },
  {
    name: "slug-login",
    path: "/:slug()/login",
    meta: loginJXTv_0Of7_Xgkgqw36qqFEYut2H4CBhFHZhfgsbEhDMMeta || {},
    component: () => import("/home/forge/merchants.smake.construction/releases/v1.44.2/pages/[slug]/login.vue")
  },
  {
    name: "slug-entrance",
    path: "/:slug()/entrance",
    meta: entrancenLLnwRSZG0N_45qF23YLGBq8GNznQ3mpmu49cx3l5u3tIMeta || {},
    component: () => import("/home/forge/merchants.smake.construction/releases/v1.44.2/pages/[slug]/entrance.vue")
  },
  {
    name: "slug-dashboard",
    path: "/:slug()/dashboard",
    component: () => import("/home/forge/merchants.smake.construction/releases/v1.44.2/pages/[slug]/dashboard.vue")
  },
  {
    name: "slug-customers",
    path: "/:slug()/customers",
    meta: indexjoEIlvs_45Nym8BR6_IPudVLGqfS9xWyxiq21O2gU6DUcMeta || {},
    component: () => import("/home/forge/merchants.smake.construction/releases/v1.44.2/pages/[slug]/customers/index.vue")
  },
  {
    name: "slug-catalog-logos-id",
    path: "/:slug()/catalog/logos/:id()",
    meta: _91id_93V0RodU15NzO_45XjKJyc4ot8G73vzFDPnN_Y1qtUVvKPAMeta || {},
    component: () => import("/home/forge/merchants.smake.construction/releases/v1.44.2/pages/[slug]/catalog/logos/[id].vue")
  },
  {
    name: "slug-catalog-logos",
    path: "/:slug()/catalog/logos",
    meta: index7_454zyM7_45J0iu2qo9ZGy9BH0EL1WjbigIUbcyxAQMhjoMeta || {},
    component: () => import("/home/forge/merchants.smake.construction/releases/v1.44.2/pages/[slug]/catalog/logos/index.vue")
  },
  {
    name: "slug-customers-id",
    path: "/:slug()/customers/:id()",
    meta: indexlJLZzyk0ho7FlNs6En7L8t0UjXLvn94o0Q14xRiKLUEMeta || {},
    component: () => import("/home/forge/merchants.smake.construction/releases/v1.44.2/pages/[slug]/customers/[id]/index.vue")
  },
  {
    name: "slug-customers-id-colors",
    path: "/:slug()/customers/:id()/colors",
    meta: colorsDByKTen_xnz336e4wHRC4UdyH_457BfXEsPPAZBMO9uYgMeta || {},
    component: () => import("/home/forge/merchants.smake.construction/releases/v1.44.2/pages/[slug]/customers/[id]/colors.vue")
  },
  {
    name: "slug-customers-customer-logos-id",
    path: "/:slug()/customers/:customer()/logos/:id()",
    meta: _91id_93qK8uUnE0mPDUP1W0S6jIoxWTtz_rxKeQTqLQAyoKnMsMeta || {},
    component: () => import("/home/forge/merchants.smake.construction/releases/v1.44.2/pages/[slug]/customers/[customer]/logos/[id].vue")
  },
  {
    name: "slug-customers-customer-logos",
    path: "/:slug()/customers/:customer()/logos",
    meta: indexw8F_9JNC8FW6MND16U7sOGBX_45t9LD11SWLz8FU8TFKYMeta || {},
    component: () => import("/home/forge/merchants.smake.construction/releases/v1.44.2/pages/[slug]/customers/[customer]/logos/index.vue")
  },
  {
    name: "slug-customers-id-favorite-variants",
    path: "/:slug()/customers/:id()/favorite-variants",
    meta: favorite_45variantsbkLViL48j4i2J0OjsuTyf_45zOhALyLD5ZV6d_45cbloaFMMeta || {},
    component: () => import("/home/forge/merchants.smake.construction/releases/v1.44.2/pages/[slug]/customers/[id]/favorite-variants.vue")
  }
]